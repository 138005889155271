import React, { FC } from "react"
import Layout from "."

import { MDXProvider } from "@mdx-js/react"
import shortcodes from "./shortcodes"

import "./layout.css"

const Page: FC = ({ children }) => (
  <Layout>
    <MDXProvider components={shortcodes}>{children}</MDXProvider>
  </Layout>
)

export default Page
